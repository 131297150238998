const forceScrollToInputOnAndroid = () => {
  if(/Android/.test(navigator.appVersion)) {
    window.addEventListener("resize", function() {
      if (document.activeElement.tagName === "INPUT" || document.activeElement.tagName === "TEXTAREA") {
        document.activeElement.scrollIntoView();
      }
    })
  }
};

export {forceScrollToInputOnAndroid};
