import { Controller } from 'stimulus';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.css';
import { French } from "flatpickr/dist/l10n/fr.js"

export default class extends Controller {

  static targets = ['start', 'end'];

  connect() {
    this.startPicker = flatpickr(this.startTarget, {
      locale: French,
      dateFormat: 'd-m-Y',
      onClose: this.closeStart.bind(this)
    });

    this.endPicker = flatpickr(this.endTarget, {
      locale: French,
      dateFormat: 'd-m-Y',
      onClose: this.closeEnd.bind(this)
    });

    this.closeStart();
    this.closeEnd();
  }

  closeStart(_selectedDates, _dateStr, _instance) {
    this.endPicker.set('minDate', this.startTarget.value);
  }

  closeEnd(_selectedDates, _dateStr, _instance) {
    this.startPicker.set('maxDate', this.endTarget.value);
  }
}
