export function displayAutocomplete(targetSelector, autocompleteItems) {
  const itemsFilter = (query, displayMatchingItems) => {
    const substringRegex = new RegExp(query, 'i');
    const matches = autocompleteItems.filter(item => substringRegex.test(item));
    displayMatchingItems(matches);
  };

  $(targetSelector).typeahead({
    hint: true,
    highlight: true,
    minLength: 1
  },
  {
    source: itemsFilter
  });
};
