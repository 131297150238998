import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [ 'label', 'currentResume' ];

  change(event) {
    this.labelTarget.innerHTML = "Modifier mon CV";
    const fileName = this.getName(event.target);

    if (!fileName) {
      return;
    }

    if (this.hasCurrentResumeTarget) {
      this.currentResumeTarget.outerHTML = `<span data-target="file-input.currentResume">${fileName}</span>`;
    } else {
      this.element.insertAdjacentHTML('afterbegin', `<div class="Resume">
        <i class="far fa-file-alt Resume-icon"></i>
        <span data-target="file-input.currentResume">${fileName}</span>
        </div>`
      );
    }
  }

  getName(input) {
    try {
      if ( input.files && input.files.length > 1 ) {
        return `${input.files.length} fichiers sélectionnés`;
      }	else {
        return input.files[0].name;
      }
    } catch (error) {
      console.error(error);
    }
  }
}
