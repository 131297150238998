import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["signup", "signin"];

  connect() {
    this.visiblePanelClass = "SignInModal-panel--visible";
  }

  toggle(event) {
    if (this.signupTarget.classList.contains(this.visiblePanelClass)) {
      this.signupTarget.classList.remove(this.visiblePanelClass);
      this.signinTarget.classList.add(this.visiblePanelClass);
    } else {
      this.signupTarget.classList.add(this.visiblePanelClass);
      this.signinTarget.classList.remove(this.visiblePanelClass);
    }
  }
}
