import { Controller } from "stimulus";
import {displayAutocomplete} from './shared/displayAutocomplete';

export default class extends Controller {

  connect() {
    displayAutocomplete('.WorkExperienceForm-profession', professions);
  }
}

const professions = ['Acheteur Industriel',
  'Agent Administratif',
  'Agent de laboratoire - aide chimiste',
  'Agent de maintenance industrielle',
  'Agent logistique - conducteur d’engins',
  'Agent logistique - Magasinier - Cariste',
  'Agent polyvalent en restauration',
  'Animateur Assurance Qualité',
  'Approvisionneur',
  'Assistant(e) RH Permanents PSC',
  'Assistant contrôle de gestion',
  'Assistant formation',
  'Assistant Polyvalent',
  'Assistant RH',
  'Assistant(e) commercial(e)',
  'Assistante ADV',
  'Automaticien',
  'Chargé assurance qualité - production',
  'Chargé d’amélioration continue',
  'Chargé d’études techniques',
  'Chargé de Communication',
  'Chargé de la gestion des projets de maintenance',
  'Chargé de projets',
  'Chargé de travaux neufs',
  'Chargé des Ressources Humaines',
  'Chargé QHSE',
  'Chaudronnier – tuyauteur - soudeur',
  'Chef d’équipe Logistique',
  'Conducteur d’équipements industriels',
  'Conducteur d’équipement d’usinage',
  'Conducteur d’équipement des industries chimiques, pharmaceutiques ou de production d’énergie',
  'Conducteur de transport de marchandises sur longue distance',
  'Conducteur Process',
  'Contrôleur de gestion',
  'Contrôleur Qualité',
  'Coordinateur paie',
  'Coordinateur Qualité Projet',
  'Dessinateur - projeteur',
  'Directeur des Systèmes d’Information',
  'Electrotechnicien',
  'Employé libre-service',
  'Exploitant(e) transport',
  'Gestionnaire assurance qualité',
  'Gestionnaire base de données',
  'Gestionnaire commercial',
  'Gestionnaire de stock - préparateur maintenance',
  'Gestionnaire logistique',
  'Gestionnaire paie',
  'Ingénieur biomédical',
  'Ingénieur en informatique industrielle',
  'Ingénieur génie chimique - génie des procédés',
  'Ingénieur génie mécanique',
  'Ingénieur industrialisation',
  'Ingénieur méthodes - maintenance mécanique',
  'Ingénieur produits pharmaceutiques',
  'Ingénieur QHSE',
  'Ingénieur R&D',
  'Ingénieur supply chain',
  'Ingénieur système',
  'Ingénieur systèmes et réseaux',
  'Manoeuvre',
  'Manutentionnaire',
  'Mécanicien monteur',
  'Monteur - ajusteur',
  'Monteur - câbleur',
  'Opérateur de production',
  'Opérateur Régleur sur Machine Outil',
  'Ouvrier de fabrication - montage - assemblage',
  'Ouvrier polyvalent en industrie',
  'Peintre industriel',
  'Responsable qualité',
  'Responsable qualité client',
  'Soudeur',
  'Technicien BE',
  'Technicien de maintenance industrielle',
  'Technicien en études, recherche et développement',
  'Technicien génie chimique – génie des procédés',
  'Technicien génie industriel',
  'Technicien laboratoire',
  'Technicien logistique en industrie',
  'Technicien méthodes',
  'Technicien méthodes logistiques',
  'Technicien Metrologie et Instrumentation',
  'Technicien ordonancement - logistique',
  'Technicien prototypes',
  'Technicien qualité',
  'Tourneur sur machine conventionnelle et à commande numérique',
  'Usineur - outilleur - fraiseur',
  'Ingénieur commercial',
  'Ingénieur qualité',
  'Opérateur de fabrication polyvalent',
  'Responsable Ressources Humaines régional',
  'Responsable grands comptes',
  'Chargé assurance qualité / production',
  'Opérateur-régleur sur machine-outil',
  'Technicien bureau d’études',
  'Agent logistique / magasinier-cariste',
  'Gestionnaire de paie',
  'Usineur / outilleur / fraiseur',
  'Technicien de laboratoire',
  'Agent de laboratoire / aide chimiste',
  'Conducteur d’équipement des industries chimiques',
  'Pharmaceutiques ou de production d’énergie',
  'Technicien ordonnancement / logistique',
  'Chargé de recrutement / gestionnaire RH',
  'Monteur - câbleur',
  'Dessinateur / projeteur',
  'Technicien ordonnancement - logistique',
  'Technicien en études',
  'Recherche et développement',
  'Chaudronnier - tuyauteur - soudeur',
  'Ingénieur méthodes',
  'Ingénieur bureau d’études',
  'Mécanicien monteur polyvalent',
];
