import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ['otherOption', 'withoutPreference']

  checkWithoutPreference() {
    this.otherOptionTargets.forEach((item) => {
      item.checked = false;
    });
  }

  checkOtherOption() {
    this.withoutPreferenceTarget.checked = false;
  }
}
