import MicroModal from 'micromodal/dist/micromodal';
import {disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks} from 'body-scroll-lock';

export function openModal(modalId) {
  const clearBodyLock = () => {
    MicroModal.close(modalId);
    clearAllBodyScrollLocks();
    document.removeEventListener('turbolinks:request-start', clearBodyLock);
  }

  MicroModal.show(modalId, {
    disableBodyScroll: true,
    onShow: function(modal) {
      disableBodyScroll(document.querySelector(`#${modal.id} .Modal-container`));
      $('form').find('*').filter(':input:visible:first').focus();
      document.addEventListener('turbolinks:request-start', clearBodyLock);
    },
    onClose: modal => enableBodyScroll(document.querySelector(`#${modal.id} .Modal-container`)),
  });
}
