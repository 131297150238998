import { Controller } from "stimulus";
import {openModal} from './shared/openModal';

export default class extends Controller {

  openModal(event) {
    const modalId = event.target.getAttribute('data-modal-id');
    openModal(modalId);
  }
}
