import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ['login', 'password'];

  initialize() {
    this.loginTarget.insertAdjacentHTML('afterend', '<small id="login-error" class="form-text invalid-feedback"></small>');
    this.passwordTarget.insertAdjacentHTML('afterend', `<small id="password-error" class="form-text invalid-feedback"></small>`);
  }

  error(event) {
    event.preventDefault();
    this.loginTarget.classList.remove('is-invalid');

    let [data, status, xhr] = event.detail;
    if (data === 'Email ou numéro de téléphone incorrect.') {
      this.loginTarget.classList.add('is-invalid');
      document.getElementById('login-error').innerHTML = data;
    } else if (data === 'Mot de passe incorrect.') {
      this.passwordTarget.classList.add('is-invalid');
      document.getElementById('password-error').innerHTML = data;
    }
  }
}
