import { Controller } from 'stimulus';
import * as moment from 'moment';

export default class extends Controller {

  static targets = ['currentMissionRenewable'];

  connect() {
    this.setCurrentMissionRenewableInputVisibility();
  }

  change() {
    this.setCurrentMissionRenewableInputVisibility();
  }

  setCurrentMissionRenewableInputVisibility() {
    if (this.year && this.month) {
      const endOfCurrentMissionDate = moment().year(this.year).month(parseInt(this.month - 1)).date(1);
      const currentMissionTotalMonths = endOfCurrentMissionDate.diff(this.startOfCurrentMissionDate, 'month');

      if (currentMissionTotalMonths >= 3 && currentMissionTotalMonths < 6) {
        return this.currentMissionRenewableTarget.classList.remove('Wizard-hidableInput--hidden');
      }
      this.currentMissionRenewableTarget.classList.add('Wizard-hidableInput--hidden');
    }
  }

  get startOfCurrentMissionDate() {
    return moment(this.data.get("startOfCurrentMissionDate"))
  }

  get year() {
    return document.getElementById('candidate_end_of_current_mission_date_1i').value;
  }

  get month() {
    return document.getElementById('candidate_end_of_current_mission_date_2i').value;
  }
}
