import { Controller } from "stimulus";
import {displayAutocomplete} from './shared/displayAutocomplete';

export default class extends Controller {

  connect() {
    displayAutocomplete('.TemporaryAgencyForm-agency', agencies);
  }
}

const agencies = [
  "Abalone-Intérim",
  "Adaptel",
  "Ad Hominem",
  "Addit",
  "Adecco",
  "Adequat Intérim",
  "Agentis",
  "Agora",
  "Agro Carrières",
  "Aile Médicale",
  "AJ Conseil",
  "Akting",
  "Alain Gavand Consultants",
  "Alister Gordon",
  "Alpha baby",
  "Alpha CDI",
  "Altédia",
  "Altis Interim",
  "Anténor",
  "Aptimen",
  "Arcanes-Conseils",
  "Archibat",
  "Arthur Hunt",
  "Artis Intérim",
  "Asymptotes Conseil",
  "Atoll Interim",
  "Batim",
  "Bense",
  "Bernard Krief Bernard Krief Conseil RH",
  "Bienfait & Associés",
  "Blue Search",
  "Bouisset Lafforgue & associés",
  "Brain SA",
  "Busy Bee",
  "Celtis",
  "Charrette Service",
  "Convergence",
  "Crit Job",
  "Easy Intérim",
  "Eliness",
  "Energie Intérim",
  "Eurolabor",
  "Expectra",
  "Gitec",
  "Groupe Actual",
  "High Tech Interim",
  "Intérim Nation",
  "J4S",
  "KS Intérim",
  "L’Appel Médical",
  "Leader Interim",
  "Leader Travail Temporaire",
  "Maxiplan",
  "Mayday",
  "MC Intérim",
  "Minerve Intérim",
  "Modelor",
  "MS Victoire",
  "Oméga Intérim",
  "Page Intérim",
  "Partner Interim",
  "Plus intérim",
  "Presticer",
  "Publirelais",
  "Quick Intérim",
  "Remat",
  "Riverchelles",
  "sbc-interim",
  "Scott Intérim",
  "Selpro",
  "SIVEA",
  "SOS Intérim",
  "Sovitrat",
  "Speed Intérim",
  "Synergie",
  "Walters Interim",
];
