import { Controller } from "stimulus";

export default class extends Controller {

  static targets = ['question', 'triggeringAnswer'];

  connect() {
    this.setFollowUpQuestionVisibility();
  }

  change(event) {
    this.setFollowUpQuestionVisibility();
  }

  isTriggeringAnswerChecked() {
    if (this.triggeringAnswerTarget.type == "radio") {
      return this.triggeringAnswerTarget.checked;
    } else {
      return this.triggeringAnswerTarget.selected;
    }
  }

  setFollowUpQuestionVisibility() {
    if (this.isTriggeringAnswerChecked()) {
      return this.questionTarget.classList.remove('Wizard-hidableInput--hidden');
    }
    return this.questionTarget.classList.add('Wizard-hidableInput--hidden');
  }
}
